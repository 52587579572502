function Footer() {
    return ( 
        <footer class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <div class="copyright-text">
                        <p class="text-white">&copy; 2023 <a href="https://vidhema.com/">Design & Developed By Vidhema</a></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
     );
}

export default Footer;