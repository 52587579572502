import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// import Blogmain from './Pages/Blogmain';
import Blogmain from './Pages/Blogmain';
import Blogdetail from './Pages/Blogdetail';
function App() {
  return (

    <BrowserRouter>
    <Routes>
     <Route path="/" element={<Home/>}></Route>
      <Route path="/blog" element={<Blogmain/>}></Route> 
      <Route path="/blogdetail/:id" element={<Blogdetail/>}></Route> 
    </Routes>
    </BrowserRouter>

  // <Blogmain />
  // <Blogdetail />

   
  );
}

export default App;
