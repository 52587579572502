import { Link } from "react-router-dom";
import constant from "../../../Constants/constant";
import axios from "axios";
import { useEffect, useState } from "react";

function Header({logo}) 

{
    const [user, setUser] = useState([])

    useEffect(() => {
        axios.get(constant.apiUrl + 'influencer/64f757d681cd3bb0f23b8bba')
            .then(res => {
                const persons = res.data;
                console.log(persons,'fbfdbbbbbbbb')
                setUser(persons);
                //  console.log(user, 'ddddd')
            })
    }, [])
    return (  
        <header id="header" class="header">
        {/* <!-- ====== Navbar ====== --> */}
        <nav class="navbar navbar-expand-lg fixed-top" style={{background: '#000'}}>
            <div class="container">
                {/* <!-- Logo --> */}
                <a class="navbar-brand logo" href="index.html">
                    <img src={user?.data?.logo} alt="logo"/>
                </a>
                {/* <!-- // Logo --> */}

                {/* <!-- Mobile Menu --> */}
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-expanded="false"><span><i class="fa fa-bars"></i></span></button>
                {/* <!-- Mobile Menu --> */}

                <div class="collapse navbar-collapse main-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item active"><Link to="/" class="nav-link" >HOME</Link></li>
                        <li class="nav-item"><a class="nav-link" href="#counter">ABOUT</a></li>
                        <li class="nav-item"><a class="nav-link" href="#service">SERVICE</a></li>
                        <li class="nav-item"><a class="nav-link" href="#portfolio">PORTFOLIO</a></li>
                        <li class="nav-item"><a class="nav-link" href="#blog">BLOG</a></li>
                        <li class="nav-item"><a class="nav-link pr0" href="#contact">CONTACT</a></li>
                    </ul>
                </div>
            </div>
        </nav>
        {/* <!-- ====== // Navbar ====== --> */}
    </header>
    );
}

export default Header;